@import "colors";
@import "breakpoints";

section {
  &.full-margin-top {
    margin-top: 90px;
    @include respond-to('medium') {
      margin-top: 140px;
    }
    @include respond-to('sxxlarge') {
      margin-top: 180px;
    }
  }
  &.half-margin-top {
    margin-top: 45px;
    @include respond-to('medium') {
      margin-top: 70px;
    }
    @include respond-to('sxxlarge') {
      margin-top: 90px;
    }
  }
  &.quarter-margin-top {
    margin-top: 22px;
    @include respond-to('medium') {
      margin-top: 35px;
    }
    @include respond-to('sxxlarge') {
      margin-top: 45px;
    }
  }

  &.full-margin-bottom {
    margin-bottom: 90px;
    @include respond-to('medium') {
      margin-bottom: 140px;
    }
    @include respond-to('sxxlarge') {
      margin-bottom: 180px;
    }
  }
  &.half-margin-bottom {
    margin-bottom: 45px;
    @include respond-to('medium') {
      margin-bottom: 70px;
    }
    @include respond-to('sxxlarge') {
      margin-bottom: 90px;
    }
  }
  &.quarter-margin-bottom {
    margin-bottom: 22px;
    @include respond-to('medium') {
      margin-bottom: 35px;
    }
    @include respond-to('sxxlarge') {
      margin-bottom: 30px;
    }
  }
}
