@import "../globals/keyframes";
@import "../settings/breakpoints";
@import "../settings/colors";

.text-areabrick {
  padding: 150px 0;
  .container {
    .content {
      text-align: center;
      .text-headline {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 50px;
      }
      .text {
        color: $black;
      }
      .buttons-row {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
      &.elements {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        .element {
          display: grid;
          grid-template-columns: 70px 350px;
          align-items: center;
          justify-content: start;
          gap: 30px;
          img {
            width: 70px;
          }
          p {
            display: grid;
            justify-content: start;
          }
        }
      }
    }
  }
  &.icons {
    padding-top: 0;
  }
}