@import "../settings/breakpoints";
@import "../settings/colors";
@import "../globals/keyframes";
footer {
  background: $black;
  overflow: hidden;
  padding: 105px 0;

  .container {
    opacity: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    @include respond-to('lg') {
      flex-direction: row;
      gap: unset;
    }
    &.show {
      animation: revealFooterContainer 1s ease-in-out;
      opacity: 1;
    }
    .footer-logo {
      width: 150px;
    }
    .items {
      display: flex;
      gap: 5vw;
      flex-direction: column;
      @include respond-to('md') {
        flex-direction: row;
      }
      .item {
        display: flex;
        flex-direction: column;
        p,
        a {
          color: $Weiss;
          font-size: 18px;
          &.headline {
            color: $Senf;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 15px;
          }
        }

      }
    }
  }
}
