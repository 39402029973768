@import "../settings/colors";
.to-reveal {
  position: relative;
  overflow: hidden;
  width: fit-content;
  padding-bottom: 10px;
  margin-bottom: -10px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    //background-color: $Khaki;
    transition: all 0.7s;
  }

  &.reveal {
    &::before {
      left: 100%;
    }
  }
}
