@import "../settings/colors";
@import "../settings/breakpoints";
@import "../globals/keyframes";
.mobile-menu {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  z-index: 100;
  padding: 10vh 0;
  opacity: 0;
  pointer-events: none;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  @include respond-to('lg') {
    opacity: 0;
    pointer-events: none;
  }
  .logo-link {
    display: block;
    width: 80%;
    max-width: 400px;
    margin: 0 auto 10vh auto;
  }
  .nav-items {
    width: fit-content;
    margin: auto;
    li {
      a {
        color: #ffffff;
        padding: 10px 0;
        display: block;
        font-size: 25px;
        font-weight: 300;
      }
    }
  }
  .contact {
    padding-top: 10%;
    width: fit-content;
    margin: auto;
    text-align: center;
    p,
    a {
      color: #ffffff;
      display: block;
      padding: 10px 0;
    }
  }
  .close-menu {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 2%;
    right: 2%;
  }
}