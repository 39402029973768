@import "../globals/keyframes";
@import "../settings/breakpoints";
@import "../settings/colors";

.call-to-action {
  min-height: 300px;
  @include respond-to('md') {
    min-height: 400px;
  }
  @include respond-to('lg') {
    min-height: 500px;
  }

  &.patrol {
    background-color: $Patrol;
  }
  &.senf {
    background-color: $Senf;
  }
  &.white {
    background-color: $Weiss;
    .container {
      .content {
        p {
          &.h2 {
            color: $Patrol;
          }
        }
        button {
          &.primary {
            &.big {
              border: 2px solid $Patrol;
              a {
                color: $Patrol;
              }
            }
          }
        }
      }
    }
  }
  &.black {
    background-color: $black;
  }
  .container {
    .blurred-img img{
      min-height: 300px;
      @include respond-to('md') {
        min-height: 400px;
      }
      @include respond-to('lg') {
        min-height: 500px;
      }
    }
    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 90%;
      p {
        &.h2 {
          color: $Weiss;
          font-style: normal;
          font-size: 40px;
          font-weight: 700;
          margin-bottom: 20px;
          @include respond-to('md') {
            font-size: 70px;
          }
          @include respond-to('lg') {
            font-size: 90px;
          }
        }
        &.slogan {
          font-size: 120px;
          color: #ffffff;
          font-family: "Style Script", sans-serif;
        }
      }
    }
  }

}