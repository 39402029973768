@use "sass:math";
@import "../settings/breakpoints";
@import "../settings/colors";

.bg {
  &.primary300 {
    background-color: $Primary300;
  }
  &.primary400 {
    background-color: $Primary400;
  }
  &.primary500 {
    background-color: $Primary500;
  }
  &.primary700 {
    background-color: $Primary700;
  }
  &.secondary300 {
    background-color: $Secondary300;
  }
  &.secondary400 {
    background-color: $Secondary400;
  }
  &.weiss {
    background-color: $Weiss;
  }
  &.black {
    background-color: $black;
  }
  &.neutral100 {
    background-color: $Neutral100;
  }
  &.neutral200 {
    background-color: $Neutral200;
  }
  &.neutral300 {
    background-color: $Neutral300;
  }
  &.neutral400 {
    background-color: $Neutral400;
  }
  &.error {
    background-color: $Error;
  }
  &.attention {
    background-color: $Attention;
  }
  &.information {
    background-color: $Information;
  }
  &.success {
    background-color: $Success;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
li,
button {
  color: $Patrol;
  &.active {
    color: $Primary300;
  }

  &.text-primary300 {
    color: $Primary300;
  }
  &.text-primary400 {
    color: $Primary400;
  }
  &.text-primary500 {
    color: $Primary500;
  }
  &.text-primary700 {
    color: $Primary700;
  }
  &.text-secondary300 {
    color: $Secondary300;
  }
  &.text-secondary400 {
    color: $Secondary400;
  }
  &.text-weiss {
    color: $Weiss;
  }
  &.text-black {
    color: $black;
  }
  &.text-neutral100 {
    color: $Neutral100;
  }
  &.text-neutral200 {
    color: $Neutral200;
  }
  &.text-neutral300 {
    color: $Neutral300;
  }
  &.text-neutral400 {
    color: $Neutral400;
  }
  &.text-error {
    color: $Error;
  }
  &.text-attention {
    color: $Attention;
  }
  &.text-information {
    color: $Information;
  }
  &.text-success {
    color: $Success;
  }
  &.thin {
    font-weight: 100;
  }
  &.light {
    font-weight: 200;
  }
  &.regular {
    font-weight: 300;
  }
  &.medium {
    font-weight: 400;
  }
  &.bold {
    font-weight: bold;
  }
  &.bold-light {
    font-weight: 600;
  }
  &.bold-medium {
    font-weight: 600;
  }
  &.bold-strong {
    font-weight: 700;
  }
  &.bold-x-strong {
    font-weight: 800;
  }
  &.bold-black {
    font-weight: 900;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}


//@function rem($px) {
//  @return math.div($px, 16) * 1rem;
//}

//*,
.body {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  font-family: "Roboto",sans-serif;
  @include respond-to('md') {
    font-size: 16px;
  }
  @include respond-to('lg') {
    font-size: 18px;
  }
  @include respond-to('xxl') {
    font-size: 22px;
  }
  .normal {
    font-style: normal;
  }
}
.h1 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto",sans-serif;
  @include respond-to('md') {
    font-size: 45px;
  }
  @include respond-to('lg') {
    font-size: 50px;
  }
  @include respond-to('xxl') {
    font-size: 55px;
  }
}
.h2 {
  font-size: 32px;
  line-height: 1.3;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto",sans-serif;
  @include respond-to('md') {
    font-size: 36px;
  }
  @include respond-to('lg') {
    font-size: 40px;
  }
  @include respond-to('xxl') {
    font-size: 44px;
  }
}
.h3 {
  font-size: 27px;
  line-height: 1.4;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto",sans-serif;
  @include respond-to('md') {
    font-size: 30px;
  }
  @include respond-to('lg') {
    font-size: 34px;
  }
  @include respond-to('xxl') {
    font-size: 37px;
  }
}
.h4,
.h5,
.h6 {
  font-size: 21px;
  line-height: 1.5;
  font-weight: 400;
  font-family: "Corporate S", sans-serif;
  @include respond-to('md') {
    font-size: 23px;
  }
  @include respond-to('lg') {
    font-size: 26px;
  }
  @include respond-to('xxl') {
    font-size: 28px;
  }
}
.caption {
  font-size: 14px;
  line-height: 1.5;
  font-family: "Corporate S", sans-serif;
  @include respond-to('md') {
    font-size: 16px;
  }
  @include respond-to('lg') {
    font-size: 17px;
  }
  @include respond-to('xxl') {
    font-size: 19px;
  }
}
.link {
  font-size: 16px;
  line-height: 1.5;
  text-decoration: underline;
  font-family: "Corporate S", sans-serif;
  cursor: pointer;
  @include respond-to('md') {
    font-size: 18px;
  }
  @include respond-to('lg') {
    font-size: 20px;
  }
  @include respond-to('xxl') {
    font-size: 22px;
  }
}
.quote {
  font-size: 21px;
  line-height: 1.5;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto",sans-serif;
  @include respond-to('md') {
    font-size: 23px;
  }
  @include respond-to('lg') {
    font-size: 26px;
  }
  @include respond-to('xxl') {
    font-size: 28px;
  }
}
.section-title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: $Secondary400;
  font-family: "Corporate S", sans-serif;
  margin-bottom: 16px;
  @include respond-to('md') {
    font-size: 18px;
  }
  @include respond-to('lg') {
    font-size: 20px;
  }
  @include respond-to('xxl') {
    font-size: 22px;
  }
}
