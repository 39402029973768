@import "../settings/colors";
@import "../settings/breakpoints";
@import "../globals/keyframes";

.swiper {
  height: 100%;
  .swiper-wrapper {
    .swiper-slide {
      position: relative;
      .bg-overlay {
        /*background-color: black;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 355px;*/
      }
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: $Weiss;
      opacity: 1;
      height: 4px;
      width: 33px;
      border-radius: 0;
      transform: skew(160deg);
      &.swiper-pagination-bullet-active {
        background-color: $Secondary300;
      }

    }
  }

}
