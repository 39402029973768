@import "../settings/colors";
@import "../settings/breakpoints";

button {
  width: fit-content;
  font-family: "Roboto", sans-serif;
  &.patrol {
    background-color: $Patrol;
    a {
      color: $Weiss;
      padding: 20px 50px;
      display: block;
    }
  }

  &.floating-primary {
    a {
      display: block;
      width: 50px;
      height: 50px;
      background-color: $Secondary300;
      position: relative;
      border-radius: 27px;
      transition: width 0.5s ease-in-out;
      span {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        font-size: 16px;
        top: 60%;
        transform: translateY(-50%);
        left: 15px;

      }
      svg {
        position: absolute;
        right: 0;
      }
    }
    &:hover {
      a {
        width: 212px;
        span {
          opacity: 1;
          pointer-events: auto;
          top: 50%;
          transition: all 1.2s ease-in-out;
        }
      }
    }
    &:focus-within {
      a {
        background-color: $Primary300;
        svg {
          #Pfad_5189,
          #Rechteck_3554 {
            fill: $Primary300;
          }
        }
      }
    }
  }
  &.primary,
  &.secondary,
  &.white {
    padding: 10px 30px;
  }
  &.primary {
    &.big {
      border: 2px solid $Weiss;
      //background-color: $Primary500;
      transition: all 0.5s ease-in-out;
      &:hover {
        //background-color: $Secondary400;
      }
      &:focus-within {
        background-color: $Primary300;
      }
      a {
        color: $Weiss;
      }
      &:disabled {
        background-color: $Neutral400;
      }
    }
    &.small {
      a {
        color: $Primary500;
        font-weight: 400;
        &:hover {
          color: $Secondary400;
        }
        &:focus-within {
          color: $Primary300;
        }
      }
      &:disabled {
        a {
          color: $Neutral400;
        }

      }
    }
  }
  &.secondary {
    &.big {
      background-color: $Secondary400;
      transition: all 0.5s ease-in-out;
      &:hover {
        background-color: $Primary500;
      }
      &:focus-within {
        background-color: $Primary300;
      }
      a {
        color: $Weiss;
      }
      &:disabled {
        background-color: $Neutral400;
      }
    }
    &.small {
      a {
        color: $Secondary400;
        font-weight: 400;
        &:hover {
          color: $Primary500;
        }
        &:focus-within {
          color: $Primary300;
        }
      }
      &:disabled {
        a {
          color: $Neutral400;
        }

      }
    }
  }
  &.white {
    &.big {
      background-color: $Weiss;
      transition: all 0.5s ease-in-out;
      &:hover {
        background-color: $Primary500;
        a {
          color: $Weiss;
        }
      }
      &:focus-within {
        background-color: $Primary300;
        a {
          color: $Weiss;
        }
      }
      a {
        color: $Primary500;
      }
      &:disabled {
        background-color: $Neutral400;
      }
    }
    &.small {
      a {
        color: $Weiss;
        font-weight: 400;
        &:hover {
          color: $Primary500;
        }
        &:focus-within {
          color: $Primary300;
        }
      }
      &:disabled {
        a {
          color: $Neutral400;
        }

      }
    }
  }
  &.search-btn {
     > * {
      display: block;
    }
  }
}
