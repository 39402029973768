@import "../settings/colors";
@import "../settings/breakpoints";
@import "../globals/keyframes";

.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 100;
  display: grid;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  img {
    max-width: 80vw;
  }
}