html{
    font-size: 16px;/*for using REM units*/
}
body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 0.85;
    color: #000000;
}
