@import "../globals/keyframes";
@import "../settings/breakpoints";
@import "../settings/colors";

.gallery {
  position: relative;
  padding: 50px 0 100px 0;
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 0;
  }
  .container {
    position: relative;
    z-index: 10;
    .gallery-intro {
      color: #ffffff;
      font-weight: 500;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 40px;

    }
    .row {
      display: grid;
      padding: 10px;
      grid-template-columns: repeat(1,1fr);
      justify-items: center;

      @include respond-to('lg') {
        //grid-template-columns: repeat(3,1fr);
      }

      .portfolio-item {
        margin-bottom: 10px;
        @include respond-to('lg') {
          margin: 10px;
        }

        .ep_portfolio_flat_style_one {
          .ep_portfolio_thumb_f_one {
            .blurred-img {
              width: calc(100vw - 20px);
              @include respond-to('lg') {
                width: 30.6vw;
              }
              @include respond-to('xl') {
                width: 31.1vw;
              }
              @include respond-to('xxl') {
                width: 31.4vw;
              }
              @include respond-to('sxxlarge') {
                width: 31.6vw;
              }
              @include respond-to('1710large') {
                width: 31.8vw;
              }
              @include respond-to('xxxxlarge') {
                width: 31.9vw;
              }

              img {
                width: calc(100vw - 20px);
                height: auto;
                @include respond-to('lg') {
                  width: 30.6vw;
                }
                @include respond-to('xl') {
                  width: 31.1vw;
                }
                @include respond-to('xxl') {
                  width: 31.4vw;
                }
                @include respond-to('sxxlarge') {
                  width: 31.6vw;
                }
                @include respond-to('1710large') {
                  width: 31.8vw;
                }
                @include respond-to('xxxxlarge') {
                  width: 31.9vw;
                }
              }
            }
          }
        }
      }
    }
  }
  &.events {
    .container {
      &.events-container {
        padding-left: 10px;
        padding-right: 10px;
        display: grid;
        grid-template-columns: repeat(1,1fr);

        @include respond-to('lg') {
          grid-template-columns: repeat(3,1fr);
        }
        .events-row {
          padding: 0;
          position: relative;
          .portfolio-item {
            display: none;
            &:first-child {
              display: block;
            }
          }
          .event-label {
            position: absolute;
            bottom: 20px;
            left: 20px;

            p {
              color: #ffffff;
              text-shadow: 1px 1px 2px black;
              &.headline {
                font-weight: 500;
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }
}