$Primary300: #3FB38E;
$Primary400: #22957E;
$Primary500: #005E5D;
$Primary700: #0E413C;

$Secondary300: #CFE444;
$Secondary400: #9FC909;

$Weiss: #ffffff;
$Neutral100: #F9F9F9;
$Neutral200: #F2F2F2;
$Neutral300: #E9E9E9;
$Neutral400: #C3C3C3;

$Error: #F4511E;
$Attention: #FFA000;
$Information: #0288D1;
$Success: #9FC909;

$White: #ffffff;
$white: #fff;
$black: #000;

$Patrol: #297C83;
$Senf: #DBC54E;