@keyframes menuAnim {
  from {
    right: -400px;
    //opacity: 0;
  }
  to {
    right: -56px;
    //opacity: 1;
  }
}
@keyframes menuAnimMobile {
  from {
    right: -400px;
    //opacity: 0;
  }
  to {
    right: -0px;
    //opacity: 1;
  }
}
@keyframes revealFooterContainer {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes closeMenuBtn {
  from {
    right: -40px;
    opacity: 0;
  }
  to {
    right: 30px;
    opacity: 1;
  }
}
@keyframes secondLevelSlideMenu {
  from {
    right: -386px;
  }
  to {
    right: -56px;
  }
}
@keyframes secondLevelSlideMenuClose {
  from {
    right: -56px;
  }
  to {
    right: -386px;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
