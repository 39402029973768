@import "settings/colors";
@import "settings/breakpoints";

html {
  scroll-behavior: smooth;

  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    &.no-scrolling {
      overflow-y: hidden;
    }
    .container {
      margin: 0 auto;
      &.regular {
        width: 90.5%;
        max-width: 1546px;
        @include respond-to('md') {
          padding: 0 5vw;
        }
        @include respond-to('lg') {
          width: 80.5%;
          padding: 0;
        }
      }
      &.medium {
        width: 90.5%;
        max-width: 1738px;
        transition: all 1s ease-in-out;

        &.main-nav {
          width: 100%;
          @include respond-to('md') {
            width: 90.5%;
          }
        }
      }
      &.large {
        width: 100%;
      }
    }

    .wrapper-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      min-height: 100vh;

      section {
        position: relative;
      }
      .hide {
        display: none;
      }
    }

    .desktop {
      display: none;
      @include respond-to('medium') {
        display: block;
      }
    }
    .mobile {
      @include respond-to('medium') {
        display: none;
      }
    }
    input[type="radio"],
    input[type="checkbox"] {
      all: revert;
    }
    .hidden {
      display: none;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
