@import "../settings/breakpoints";
@import "../settings/colors";
@import "../globals/keyframes";

.error-page {
  display: grid;
  justify-items: center;
  align-content: center;
  height: 100vh;
  gap: 30px;
  background: transparent linear-gradient(238deg, #26A68D 0%, #0B3430 100%) 0% 0% no-repeat padding-box;
  svg {
    width: 100%;
  }
}
