@import "../settings/colors";
@import "../settings/breakpoints";
@import "../globals/keyframes";

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $black;
  padding: 10px;
  @include respond-to('lg') {
    padding: 20px 50px;
  }

  .logo-link {
    width: 200px;
    display: flex;
    @include respond-to('xl') {
      width: 400px;
    }
  }

  .nav-items {
    display: flex;
    gap: 30px;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    &.desktop {
      display: none;
      @include respond-to('lg') {
        display: flex;
      }
    }
    @include respond-to('lg') {
      opacity: 1;
      pointer-events: all;
      position: relative;
    }

    li {
      a {
        color: $Weiss;
        font-weight: 300;
        position: relative;
        padding: 20px 0;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          top: 0;
          background-color: $Weiss;
          opacity: 0;
        }
        &:hover {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
  .open-menu {
    width: 50px;
    height: 50px;
    @include respond-to('lg') {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }
}

