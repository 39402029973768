@import "../settings/colors";
@import "../settings/breakpoints";
@import "../globals/keyframes";

.unordered-list {
  position: relative;
  margin-top: 20px;
  margin-bottom: 110px;
  @include respond-to('lg') {
    margin-top: 33px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    background-image: url("/assets/images/list-line.svg");
  }
  li {
    position: relative;
    padding: 10px 0 10px 13px;
    &::before {
      position: absolute;
      content: "";
      height: 9px;
      width: 5px;
      background-image: url("/assets/images/list-arrow.svg");
      left: 0;
      top: 25px;
      background-size: cover;
    }
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-image: url("/assets/images/list-line.svg");
    }
  }
}
